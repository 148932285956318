<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche formation #{{formation.id}}</h3>
        </div>
        <div class="card-title">
          <b-button block :variant="formationStatus.color" size="lg" style="font-size:1.20em">{{formationStatus.titre}}</b-button>
        </div>
        <div class="card-toolbar">
          <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="save(true)"><i class="icon-md fas fa-trash"></i></b-button>
          <div class="mr-1" v-if="$store.role === 'isAdmin'">
            <b-dropdown size="sm" variant="danger" html='<i class="icon-md fas fa-envelope"></i>'>
              <b-dropdown-item v-for="t in tpls.filter(t => t.type === 'formation')" :key="t.id" @click="sendTpl(t.id)">{{t.titre}}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="mr-1" v-if="$store.role === 'isAdmin'">
            <b-dropdown size="sm" variant="primary" html='<i class="icon-md fas fa-user"></i>'>
              <b-dropdown-item v-for="u in users" :key="u.id" @click="changeUser(u.id)">{{u.prenom}} {{u.nom[0]}}.</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="mr-1" v-if="$store.role === 'isAdmin'">
            <b-dropdown size="sm" variant="dark" html='<i class="fas fa-thermometer-three-quarters"></i>'>
              <b-dropdown-item v-for="l in parametrage.formationStatus" :key="l.id" @click="changeStatus(l.id)" :active="l.id === formation.status_id">{{l.titre}}</b-dropdown-item>
            </b-dropdown>
          </div>
          <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="save"><i class="icon-md fas fa-save"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <AppForm v-model="formation"></AppForm>
          <b-row>
            <b-col>
              <b-form-group label="Type:">
                <model-select :options="parametrage.ProduitType" v-model="formation.produit_type_id" placeholder="Selectionnez votre type" @change="changeProduit()"></model-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Formation:">
                <model-select :options="parametrage.produits.filter(p => p.type_id == formation.produit_type_id)" v-model="formation.produit.id" placeholder="Selectionnez votre formation" @change="changeProduit()"></model-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Prix:">
                <b-form-input v-model="formation.produit.prix" type="text" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Duree:">
                <b-form-input v-model="formation.produit.duree" type="text" disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Debut:">
                <b-form-datepicker v-model="formation.produit.debut"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Fin:">
                <b-form-datepicker v-model="formation.produit.fin"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Action:" v-if="$store.role === 'isAdmin'">
                <b-button block variant="primary" v-if="formation.payer === false" @click="formation.payer = true">Payer</b-button>
                <b-button block variant="danger" v-else @click="formation.payer = false">Annuler un paiement</b-button>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Somme Paye:" v-if="$store.role === 'isAdmin'">
                <b-form-input v-model="formation.prix_paye" type="text"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div class="card card-custom gutter-b" v-if="formation.questions.length > 0">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Questionnaire</h3>
        </div>
      </div>
      <div class="card-body">
        <b-row v-for="(q,i) in formation.questions" :key="i">
          <b-col>
            <b-form-group :label="q.request">
              <b-form-input v-model="q.answer" type="text" placeholder="Entre la reponse" v-if="q.type == 'libre'"></b-form-input>
              <select class="form-control" v-else v-model="q.answer">
                <option v-for="(r,i) in q.res" :value="r.r" :key="i">{{r.r}}</option>
              </select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row>
      <b-col md="6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Commentaire</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form-group label="Commentaire:">
              <b-form-textarea v-model="formation.commentaire" rows="4" :readonly="$store.role === 'isUser'"></b-form-textarea>
            </b-form-group>
            <b-form-group label="Ajouter:">
              <b-input-group>
                <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
                <template #append>
                  <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
                </template>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Identifiants</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form-group label="URL:">
              <b-form-input v-model="formation.login.url" type="text" required placeholder="Entrez l'url"></b-form-input>
            </b-form-group>
            <b-form-group label="Nom d'utilisateur:">
              <b-form-input v-model="formation.login.user" type="text" required placeholder="Entrez le nom d'utilisateur"></b-form-input>
            </b-form-group>
            <b-form-group label="Mot de passe:">
              <b-form-input v-model="formation.login.password" type="text" required placeholder="Entrez le mot de passe"></b-form-input>
            </b-form-group>
            <b-form-group label="Certificat:" v-if="formation.login.certif_url === null">
              <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
            </b-form-group>
            <b-form-group label="Certificat:" v-else>
              <b-button-group>
                <b-button variant="primary" :href="formation.login.certif_url" target="_blank"><i class="fas fa-eye"></i></b-button>
                <b-button variant="danger" @click="formation.login.certif_url = null"><i class="fas fa-trash"></i></b-button>
              </b-button-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Historique</h3>
        </div>
      </div>
      <div class="card-body" style="height: 200px; overflow: scroll;">
        <b-row v-for="(l,i) in formation.logs" :key="i" class="mt-3">
          <b-col :style="i%2 == 0 ? '' : 'text-align: right;'">
            <b-button variant="light" size="sm" class=" mr-1" style="color:black">{{l.body}}</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ModelSelect } from 'vue-search-select';
import AppForm from "@/view/layout/custom/Form.vue";

export default {
  data(){
    return {
      tpls:[],
      users:[],
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      formation:{
        prenom: null,
        nom: null,
        password: null,
        email: null,
        user_id: null,
        status_id: null,
        source_id: null,
        date_create: null,
        date_update: null,
        status: null,
        prix_paye:0,
        payer: null,
        logs: [],
        formation_id: null,
        produit: {
          id: null,
          titre: null,
          prix: null,
          prix_achat: null,
          duree: null,
          url_gide: null,
          url_gov: null,
          type_id: null,
          debut: null,
          fin: null
        },
        questions:[],
        produit_type_id:null,
        login: {
          url: null,
          user: null,
          password: null,
          certif_url: null
        },
        id: null
      },
      commentaireTmp:null,
      parametrage:{
        role:[],
        status:[],
        formationStatus:[],
        produits:[],
        ProduitType:[]
      },
    };
  },
  components: {
    ModelSelect,
    AppForm
  },
  computed: {
    formationStatus(){
      let status = this.parametrage.formationStatus.find(l => l.id === this.formation.status_id);
      if(status){
        return status;
      }else{
        return {
          id:1,
          titre:"A appeler",
          color:"primary"
        }
      }
    }
  },
  methods: {
    save(sup = false) {
      if(sup === true){
        this.formation.status = 0;
      }
      this.$store.back.ajax('/formation/update', this.formation, (res) => {
        if(res.status === true){
          this.formation = res.data;
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          if(sup === true){
            this.$router.push({path:`/formation/liste`});
          }
        }
      });
    },
    changeUser(id){
      this.formation.user_id = id;
      this.save();
    },
    changeStatus(id){
      if(this.formation.status_id === id){
        return false;
      }
      var user = this.$store.back.getUser();
      var now = this.$store.back.now();
      this.formation.logs.push({
        user_id:user.id,
        module:"formation",
        old: this.formation.status_id,
        new: id,
        body: `La formation a change de statut de '${this.parametrage.formationStatus.find(l => l.id === this.formation.status_id).titre}' a '${this.parametrage.formationStatus.find(l => l.id === id).titre}' par ${user.prenom} ${user.nom} le ${this.$store.back.timestampToDate(now)}`,
        date_create:now
      });
      this.formation.status_id = id;
    },
    changeProduit(){
    },
    saveCal(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      var user = this.$store.back.getUser();
      var date = new Date();
      this.formation.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    handleFile(e){
      const fileUploaded = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var params = {
          file: e.target.result,
          formation_id: this.formation.id
        }
        this.$store.back.ajax('/formation/certificat', params, (res) => {
          if(res.status === true){
            this.formation.login.certif_url = res.data;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    sendTpl(id){
      var user = this.$store.back.getUser();
      var now = this.$store.back.now();
      if(!this.formation.logs){
        this.formation.logs = [];
      }
      this.formation.logs.push({
        user_id:user.id,
        module:"template",
        old: null,
        new: id,
        body: `Le mail type '${this.tpls.find(t => t.id === id).titre}' a ete envoye par ${user.prenom} ${user.nom} le ${this.$store.back.timestampToDate(now)}`,
        date_create:now
      });
      this.save();
      const params = {
        model:"formation",
        id:id,
        model_id:this.formation.id
      }
      this.$store.back.ajax('/template/send', params, (res) => {
        if(res.status === true){
          this.$bvToast.toast(res.data, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      })
    },
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
    'formation.produit_type_id': function(){
      var type = this.parametrage.ProduitType.find(p => p.id == this.formation.produit_type_id);
      if(!type){
        return false;
      }
      this.formation.questions = type.questions;

    },
    'formation.produit.id': function(){
      var produit = this.parametrage.produits.find(p => p.id == this.formation.produit.id);
      if(!produit){
        return false;
      }
      this.formation.produit.titre = produit.titre;
      this.formation.produit.prix = parseInt(produit.prix);
      this.formation.produit.prix_achat = parseInt(produit.prix_achat);
      this.formation.produit.duree = parseInt(produit.duree);
      this.formation.produit.url_gide = produit.url_gide;
      this.formation.produit.url_gov = produit.url_gov;
      this.formation.produit.type_id = produit.type_id;
    }
  },
  beforeMount(){
    this.$store.back.ajax(`/formation/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        this.formation = res.data.formation;
        this.users = res.data.users;
        this.tpls = res.data.tpls;
        let params = {
          modules: ["UserRole","FormationStatus","Produit","ProduitType"]
        };
        this.$store.back.ajax('/parametrage/all', params, (res) => {
          if(res.status === true){
            this.parametrage.role = res.data['UserRole'];
            this.parametrage.status = res.data['status'];
            this.parametrage.formationStatus = res.data['FormationStatus'];
            this.parametrage.produits = res.data['Produit'];
            this.parametrage.ProduitType = res.data['ProduitType'];
            this.parametrage.produits.forEach(item => {
              item.text = item.titre+' '+item.prix+'euro '+item.duree+' sem';
              item.value = item.id;
            });
            this.parametrage.ProduitType.forEach(item => {
              item.text = item.titre;
              item.value = item.id;
            });

          }
        });
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
